import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { BText } from "./chakra/BText"
// import CookieConsent from "react-cookie-consent"
// import { Slide, SlideFade, useDisclosure,  } from "@chakra-ui/react"
import Plausible from "./Plausible"

function Layout({
  title,
  children,
  backgroundColor,
  logo,
  duration,
  delay,
  footerBackgroundColor,
  footerColor,
  strapiUrl,
  textColor,
  siteUrl,
  hamburgerColor,
  themeColor,
}) {
  const [toggleNav, setToggleNav] = React.useState(false)

  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  // const { isOpen, onToggle } = useDisclosure()

  useEffect(() => {
    setTimeout(() => {
      onToggle()
    }, 2500)
  }, [])

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
    }
  }, [])

  useEffect(() => {
    if (document) {
      const [metaTag] = document.getElementsByName("theme-color")
      if (metaTag) {
        const content = metaTag.getAttribute("content")
        if (backgroundColor) {
          if (content && content !== backgroundColor) {
            metaTag.setAttribute("content", backgroundColor)
          }
        } else if (themeColor) {
          metaTag.setAttribute("content", themeColor)
        }
      }
    }
  }, [themeColor])

  const navTextColor = textColor ? textColor : "black"
  const logoSrc = logo
    ? logo
    : `${strapiUrl}/uploads/bitlight_logo_Y_B_2x_b730837942.png`
  const hamburgerBackgroundColor = hamburgerColor ? hamburgerColor : "black"

  const header = (
    <div
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <header className={"site-head max-width"}>
        <div className={"site-head-container"}>
          <a
            className={"nav-burger"}
            href={"#"}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className={"hamburger hamburger--collapse"}
              aria-label={"Menu"}
              role={"button"}
              aria-controls={"navigation"}
            >
              <div className={"hamburger-box"}>
                <div
                  className={"hamburger-inner"}
                  style={{
                    backgroundColor: hamburgerBackgroundColor,
                  }}
                />
              </div>
            </div>
          </a>
          <nav id={"swup"} className={"site-head-left"}>
            <ul className={"nav"} role={"menu"}>
              {/*<li className={"nav-elements"} role={"menuitem"}>*/}
              {/*  <AniLink*/}
              {/*    paintDrip*/}
              {/*    to={"/projects"}*/}
              {/*    hex={"#24272A"}*/}
              {/*    style={{*/}
              {/*      flex: 1,*/}
              {/*      zIndex: 10000,*/}
              {/*      color: navTextColor,*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <BText as={"span"}>Projects</BText>*/}
              {/*  </AniLink>*/}
              {/*</li>*/}
              {/*<li className={'nav-elements'} role={'menuitem'}>*/}
              {/*  <AniLink*/}
              {/*    paintDrip*/}
              {/*    to={'/contact'}*/}
              {/*    hex={'#ffd600'}*/}
              {/*    style={{*/}
              {/*      flex: 1,*/}
              {/*      zIndex: 10000,*/}
              {/*      color: navTextColor,*/}
              {/*    }}*/}
              {/*  >*/}
              {/*        Contact*/}
              {/*  </AniLink>*/}
              {/*</li>*/}
            </ul>
          </nav>
          <div className={"site-head-center"}>
            <Link className={"site-head-logo"} to={"/"}>
              <img alt={"Bitlight"} src={logoSrc} />
            </Link>
          </div>
          <div className={"site-head-left"}>
            <ul className={"nav"} role={"menu"}>
              <li className={"nav-elements"} role={"menuitem"}>
                <AniLink
                  paintDrip
                  to={"/contact"}
                  hex={"#ffd600"}
                  style={{
                    flex: 1,
                    zIndex: 10000,
                    color: navTextColor,
                  }}
                >
                  <BText as={"span"}>Contact</BText>
                </AniLink>
              </li>
            </ul>
            {/*<div className={'social-links'}>*/}
            {/*  <a*/}
            {/*    href={'https://www.facebook.com'}*/}
            {/*    title={'Facebook'}*/}
            {/*    target={'_blank'}*/}
            {/*    rel={'noopener noreferrer'}*/}
            {/*  >*/}
            {/*    Facebook*/}
            {/*  </a>*/}
            {/*  <a*/}
            {/*    href={'https://twitter.com'}*/}
            {/*    title={'Twitter'}*/}
            {/*    target={'_blank'}*/}
            {/*    rel={'noopener noreferrer'}*/}
            {/*  >*/}
            {/*    Twitter*/}
            {/*  </a>*/}
            {/*  <Link*/}
            {/*    to={'/rss.xml'}*/}
            {/*    title={'RSS'}*/}
            {/*    target={'_blank'}*/}
            {/*    rel={'noopener noreferrer'}*/}
            {/*  >*/}
            {/*    RSS*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>
        </div>
      </header>
    </div>
  )

  return (
    <>
      <Plausible />
      <div className={`site-wrapper ${toggleNav ? "site-head-open" : ""}`}>
        <Fade
          triggerOnce={true}
          direction={isDesktop ? "down" : "left"}
          duration={duration ? duration : 1000}
          delay={delay ? delay : 1800}
        >
          {header}
        </Fade>
        <main
          id={"site-main"}
          className={`site-main ${toggleNav ? "display-none" : ""}`}
        >
          <div id={"swup"}>{children}</div>
        </main>
        <footer
          className={"site-foot"}
          style={{
            color: footerColor,
            backgroundColor: footerBackgroundColor,
          }}
        >
          <BText as={"span"}>&copy; {new Date().getFullYear()} </BText>
          <Link
            style={{
              color: footerColor,
            }}
            to={"/"}
          >
            <BText as={"span"}>{title}</BText>
          </Link>

          <br />
          <AniLink
            paintDrip
            to={"/privacy-policy"}
            hex={"#ffd600"}
            style={{
              flex: 1,
              zIndex: 10000,
              color: navTextColor,
            }}
          >
            <BText as={"span"}> Privacy Policy</BText>
          </AniLink>
          <span> &#183;</span>
          <AniLink
            paintDrip
            to={"/cookie-policy"}
            hex={"#ffd600"}
            style={{
              flex: 1,
              zIndex: 10000,
              color: navTextColor,
            }}
          >
            <BText as={"span"}> Cookie Policy</BText>
          </AniLink>
        </footer>
        {/*<Slide in={isOpen} direction={"left"}>*/}
        {/*<CookieConsent   buttonStyle={{ color: "#4e503b", fontSize: "13px" }} contentStyle={{margin: "auto", padding: "16px"}} buttonText={"I Consent"}>We use cookies. By pressing on &quot;I Consent&quot;, you consent to our&nbsp;*/}
        {/*  <Link to={"/cookie-policy"} style={{textDecoration: "underline"}}>Cookie policy</Link>*/}
        {/*</CookieConsent>*/}
        {/*</Slide>*/}
      </div>
    </>
  )
}

export default Layout
