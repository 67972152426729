import React from "react"
import { Helmet } from "react-helmet"

function Plausible() {
  return (
    <Helmet>
      <script defer data-domain={"bitlight.dev"} src={"https://plausible.io/js/plausible.js"}/>
    </Helmet>
  )
}

export default Plausible
